
























































































































import Vue from "vue";
import { ActionTypes } from "@/store/action-types";
import { mapGetters } from "vuex";
import { MutationTypes } from "@/store/mutation-types";
import { Brand, Company } from "@/models/company";
import { Component } from "vue-property-decorator";

@Component({
  computed: mapGetters([
    "isLoggedIn",
    "getTitle",
    "getSelectedCollectionId",
    "getCreatedCollectionId",
    "getCompany",
    "getAllCompanies",
    "getCurrentBrand",
    "getLogin"
  ])
})
export default class Dashboard extends Vue {
  getCompany!: Company | null;
  getCurrentBrand!: Brand | null;
  getAllCompanies!: Company[];
  private appDrawer = false;
  private selected = 1;
  private brand: Brand | null = null;
  private loading = false;

  async logout() {
    this.loading = true;
    console.log("Logging out");
    this.$store.commit(MutationTypes.SET_LOGIN, null);
    sessionStorage.clear();
    this.$authModule.logout();
    setTimeout(() => {
      this.loading = false;
    }, 1000);
  }
  get dropdownMenu() {
    const temp: Brand[] = [];
    this.getAllCompanies.forEach(element => {
      element.brands.forEach(el => {
        temp.push(el);
      });
    });
    return temp;
  }
  changedBrand(brand: Brand) {
    console.log("Changed ", brand);
    this.$store.commit(MutationTypes.SET_CURRENT_BRAND, brand);
  }
  async mounted() {
    await this.$store.dispatch(ActionTypes.loadCompanyAndBrand, { vm: this });
    this.brand = this.getCurrentBrand;
  }
}
